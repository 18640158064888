<template>
  <a class="link" @click="setCallbackConfig">
    <slot />
  </a>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueCookies from "vue-cookies";

export default {
  name: "OAuthLink",
  components: {},
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_AUTH_ROOT}/api/oauth2/auth?client_id=${process.env.VUE_APP_CLIENT_ID}&scope=openid&response_type=code&state=`,
      hydraState: "",
    };
  },
  created() {
    if (this.$route.query && this.$route.query.redirectToLogin === "true") {
      this.setCallbackConfig();
    }
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    oauthUrl() {
      if (!this.isLoggedIn) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hydraState = this.generateHydraState();
        const authUrl = this.baseUrl + this.hydraState;
        return authUrl;
      }
      return "/";
    },
  },
  methods: {
    ...mapActions(["resetLoginDetails", "clearLocalStore"]),
    dec2hex(dec) {
      return `0${dec.toString(16)}`.substr(-2);
    },
    generateHydraState() {
      const arr = new Uint8Array(23 / 2);
      window.crypto.getRandomValues(arr);
      return Array.from(arr, this.dec2hex).join("");
    },
    setCallbackConfig() {
      const url = this.oauthUrl;
      if (!this.isLoggedIn) {
        localStorage.callbackRoute = this.$route.path;
        localStorage.hydraState = this.hydraState;
        window.location = url;
        return;
      }
      // TODO: Front channel logout w/ hydra
      this.resetLoginDetails();

      const { userType } = localStorage;
      localStorage.removeItem("currentLocation");
      localStorage.removeItem("selected_vendor");
      localStorage.removeItem("locationStatus");
      localStorage.removeItem("locationName");
      localStorage.removeItem("orgId");
      if (userType === "practiceUser") {
        const landingPageType = localStorage.landingPage === "bhrt" ? "bhrt" : "labtest";
        const labtestPage = "ifr2";
        const bhrtpage = "ifr3";
        const LAB_TEST_PORTAL = `${process.env.VUE_APP_LABTEST_ROOT}`;
        const PATIENT_PORTAL = `${process.env.VUE_APP_BHRT_PORTAL}`;

        if (landingPageType === "labtest") {
          this.postMessage(labtestPage, LAB_TEST_PORTAL);
          window.location = `${process.env.VUE_APP_LABTEST_ROOT}/simpatra`;
        } else {
          this.postMessage(bhrtpage, PATIENT_PORTAL);
          window.location = `${process.env.VUE_APP_BHRT_PORTAL}/simpatra`;
        }
        VueCookies.set("isPatient", false);
        this.clearLocalStore();
      } else {
        this.clearLocalStore();
        window.location = url;
      }
    },
    postMessage(ifr, portal) {
      const postMsg = {
        access_token: "",
        id_token: "",
      };
      this.postCrossDomainMessage(postMsg, ifr, portal);
    },
    postCrossDomainMessage(msg, ifr, portal) {
      const win = document.getElementById(ifr).contentWindow;
      win.postMessage(msg, portal);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}
</style>
